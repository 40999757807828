import {BASE_URL} from "../constants";

import {fetchTestimonialsError, fetchTestimonialsPending, fetchTestimonialsSuccess} from "./index";

function fetchTestimonials() {
    return dispatch => {
        dispatch(fetchTestimonialsPending());
        fetch(BASE_URL + "testmon")
            .then(res => res.json())
            .then(res => {
                if (res.data !== undefined) {
                    throw(res.message);
                }
                dispatch(fetchTestimonialsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchTestimonialsError(error));
            })
    }
}

export default fetchTestimonials;