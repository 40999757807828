import {
    REGISTER_ORDER_ERROR,
    REGISTER_ORDER_PENDING,
    REGISTER_ORDER_SUCCESS,
    RESET_REGISTRATION_STATES,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_FIRST_NAME,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_LAST_NAME,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET,
    UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET_NUMBER,
    UPDATE_IS_SHIPPING_ADDRESS_REQUIRED,
    UPDATE_REGISTRATION_COUPON_LINES,
    UPDATE_REGISTRATION_CUSTOMER_NOTE,
    UPDATE_REGISTRATION_FORM_FIELD_AGB_ACCEPTED,
    UPDATE_REGISTRATION_FORM_FIELD_EMAIL,
    UPDATE_REGISTRATION_FORM_FIELD_PHONE,
    UPDATE_REGISTRATION_FORM_SNACKBAR,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_CITY,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_COUNTRY,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_FIRST_NAME,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_LAST_NAME,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_POSTCODE,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STATE,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET,
    UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET_NUMBER,
} from "../constants";

const initialState = {
    billingFirstName: "",
    billingLastName: "",
    billingStreet: "",
    billingStreetNumber: "",
    billingCity: "",
    billingState: "",
    billingPostcode: null,
    billingCountry: "CH",

    shippingFirstName: "",
    shippingLastName: "",
    shippingStreet: "",
    shippingStreetNumber: "",
    shippingCity: "",
    shippingState: "",
    shippingPostcode: "",
    shippingCountry: "CH",

    email: "",
    phone: "",
    couponLines: "",
    customerNote: "",
    AGBaccepted: false,
    isShippingAddressRequired: false,
    sent: false,
    success: false,
    error: false,
    errorCode: "",
    isSnackbarOpen: true,
    sending: false,
}

export function register(state = initialState, action) {

    switch (action.type) {

        case RESET_REGISTRATION_STATES:
            return {
                ...state,
                success: false,
            }
        case REGISTER_ORDER_PENDING:
            return {
                ...state,
                sending: true,
                sent: false,
                success: false,
                error: null
            }

        case REGISTER_ORDER_SUCCESS:
            return {
                ...state,
                sending: false,
                sent: true,
                success: true,
                error: null
            }

        case REGISTER_ORDER_ERROR:
            return {
                ...state,
                sent: true,
                error: action.error,
                errorCode: action.errorCode,
                sending: false
            }

        case UPDATE_REGISTRATION_FORM_SNACKBAR:
            return {
                ...state,
                isSnackbarOpen: action.isSnackbarOpen
            }

        case UPDATE_REGISTRATION_CUSTOMER_NOTE:
            return {
                ...state,
                customerNote: action.customerNote
            }

        case UPDATE_REGISTRATION_COUPON_LINES:
            return {
                ...state,
                couponLines: action.couponLines
            }

        case UPDATE_IS_SHIPPING_ADDRESS_REQUIRED:
            return {
                ...state,
                isShippingAddressRequired: action.isShippingAddressRequired
            }

        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_FIRST_NAME:
            return {
                ...state,
                billingFirstName: action.billingFirstName
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_LAST_NAME:
            return {
                ...state,
                billingLastName: action.billingLastName
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET:
            return {
                ...state,
                billingStreet: action.billingStreet
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET_NUMBER:
            return {
                ...state,
                billingStreetNumber: action.billingStreetNumber
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE:
            return {
                ...state,
                billingPostcode: action.billingPostcode
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE:
            return {
                ...state,
                billingState: action.billingState
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY:
            return {
                ...state,
                billingCountry: action.billingCountry
            }
        case UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY:
            return {
                ...state,
                billingCity: action.billingCity
            }

        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_FIRST_NAME:
            return {
                ...state,
                shippingFirstName: action.shippingFirstName
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_LAST_NAME:
            return {
                ...state,
                shippingLastName: action.shippingLastName
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET:
            return {
                ...state,
                shippingStreet: action.shippingStreet
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET_NUMBER:
            return {
                ...state,
                shippingStreetNumber: action.shippingStreetNumber
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_POSTCODE:
            return {
                ...state,
                shippingPostcode: action.shippingPostcode
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STATE:
            return {
                ...state,
                shippingState: action.shippingState
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_COUNTRY:
            return {
                ...state,
                shippingCountry: action.shippingCountry
            }
        case UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_CITY:
            return {
                ...state,
                shippingCity: action.shippingCity
            }

        case UPDATE_REGISTRATION_FORM_FIELD_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case UPDATE_REGISTRATION_FORM_FIELD_PHONE:
            return {
                ...state,
                phone: action.phone
            }
        case UPDATE_REGISTRATION_FORM_FIELD_AGB_ACCEPTED:
            return {
                ...state,
                AGBaccepted: action.AGBaccepted
            }

        default:
            return state;
    }
}