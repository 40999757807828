import React from 'react';
import {Typography} from "@material-ui/core";

// CSS
import "./FooterMiddle.scss";
import {Link} from "react-router-dom";

import agb from "../../assets/downloads/agb.pdf";

const FooterMiddle = () => {
    return (
        <div>
            <div className="middle-footer-container">
                <a href={agb} target="_blank" rel="noopener noreferrer"><Typography>AGB</Typography></a>
                <Link to="/impressum"><Typography>&nbsp;|&nbsp;Impressum</Typography></Link>
                <Link to="/dataprotection"><Typography>&nbsp;|&nbsp;Datenschutzerklärung</Typography></Link>
            </div>
        </div>
    );
};

export default FooterMiddle;
