import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {CookieBanner} from '@palmabit/react-cookie-law';

// Material-UI
import {StylesProvider} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {theme} from "./globalStyles";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import fetchTestimonials from "./actions/testimonials";
import fetchPosts from "./actions/posts";
import fetchPages from "./actions/pages";
import fetchCourses from "./actions/courses";

// CSS
import './App.scss';

// Components
import Header from "./components/header/Header";
import MainLandingPage from "./pages/landingPage/MainLandingPage/MainLandingPage";
import Footer from "./components/footer/Footer";
import MainCoursePage from "./pages/coursePage/MainCoursePage";
import MainAboutPage from "./pages/aboutPage/MainAboutPage";
import MainContactPage from "./pages/contactPage/MainContactPage";
import MainBusinessCoursesPage from "./pages/businessCoursesPage/MainBusinessCoursesPage";
import SingleCourseCard from "./components/courseCards/SingleCourseCard";
import ContactButton from "./components/contactButton/ContactButton";
import fetchProducts from "./actions/products";
import Checkout from "./pages/checkoutPage/Checkout";
import MainImpressumPage from "./pages/impressumPage/MainImpressumPage";
import MainDataprotectionPage from "./pages/dataprotectionPage/DataprotectionPage";
import fetchLandingposts from "./actions/landingposts";
import ScrollToTop from "./components/misc/ScrollToTop";

const responsiveTheme = responsiveFontSizes(theme);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchPages();
        this.props.fetchPosts();
        this.props.fetchTestimonials();
        this.props.fetchCourses();
        this.props.fetchProducts();
        this.props.fetchLandingposts();
    }

    render() {
        return (
            <ThemeProvider theme={responsiveTheme}>
                <StylesProvider injectFirst>
                    <Router>
                        <ScrollToTop/>
                        <div>
                            <CssBaseline/>
                            <Header/>
                            <main>
                                <Switch>
                                    <Route path="/courses/:id" component={SingleCourseCard}/>
                                    <Route path="/courses" component={MainCoursePage}/>
                                    <Route path="/checkout" component={Checkout}/>
                                    <Route path="/business-courses" component={MainBusinessCoursesPage}/>
                                    <Route path="/contact" component={MainContactPage}/>
                                    <Route path="/about" component={MainAboutPage}/>
                                    <Route path="/impressum" component={MainImpressumPage}/>
                                    <Route path="/dataprotection" component={MainDataprotectionPage}/>
                                    <Route path="/" component={MainLandingPage}/>
                                </Switch>
                            </main>
                            <Footer/>
                            <ContactButton/>
                            <CookieBanner
                                message='Wir verwenden Cookies (auch von Drittanbietern), um Informationen über die Nutzung unserer Websites durch die Besucher zu sammeln. Diese Cookies helfen uns dabei, Ihnen das bestmögliche Online-Erlebnis zu bieten, unsere Websites ständig zu verbessern und Ihnen Angebote zu unterbreiten, die auf Ihre Interessen zugeschnitten sind. Mit dem Klick auf den Button "Zustimmen" erklären Sie sich mit der Verwendung von Cookies einverstanden.'
                                wholeDomain={true}
                                onAccept={() => {
                                }}
                                onAcceptPreferences={() => {
                                }}
                                onAcceptStatistics={() => {
                                }}
                                onAcceptMarketing={() => {
                                }}
                                policyLink="/dataprotection"
                                privacyPolicyLinkText="Datenschutzerklärung"
                                necessaryOptionText="Notwendig"
                                preferencesOptionText="Einstellungen"
                                statisticsOptionText="Statistiken"
                                acceptButtonText="Zustimmen"
                                managePreferencesButtonText="Cookies verwalten"
                                savePreferencesButtonText="Speichern"
                                statisticsDefaultChecked
                                marketingDefaultChecked
                                preferencesDefaultChecked
                            />
                        </div>
                    </Router>
                </StylesProvider>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    pagesError: state.pages.pagesError,
    pages: state.pages.pages,
    pagesPending: state.pages.pending,
    postsError: state.posts.error,
    posts: state.posts.posts,
    postsPending: state.posts.pending,
    testimonialsError: state.testimonials.error,
    testimonials: state.testimonials.testimonials,
    testimonialsPending: state.testimonials.pending,
    coursesError: state.courses.error,
    courses: state.courses.courses,
    coursesPending: state.courses.pending,
    productsPending: state.products.pending,
    productsError: state.products.error,
    products: state.products.products,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchPages: fetchPages,
    fetchPosts: fetchPosts,
    fetchTestimonials: fetchTestimonials,
    fetchCourses: fetchCourses,
    fetchProducts: fetchProducts,
    fetchLandingposts: fetchLandingposts
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
