import {FETCH_LANDINGPOSTS_ERROR, FETCH_LANDINGPOSTS_PENDING, FETCH_LANDINGPOSTS_SUCCESS} from "../constants";

const initialState = {
    pending: false,
    landingposts: [],
    error: null,
}

export function landingposts(state = initialState, action) {
    switch (action.type) {
        case FETCH_LANDINGPOSTS_PENDING:
            return {
                ...state,
                pending: true
            }

        case FETCH_LANDINGPOSTS_SUCCESS:
            return {
                ...state,
                pending: false,
                landingposts: action.landingposts
            }

        case FETCH_LANDINGPOSTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        default:
            return state;
    }
}
