import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import IconBar from "./IconBar";
import LowerFooterContent from "./LowerFooterContent";

// CSS
import "./Footer.scss";


const Footer = () => {
    return (
        <>
            <CssBaseline/>
            <div className="upper-footer">
                <IconBar/>
            </div>

            <div className="lower-footer">
                <LowerFooterContent/>
            </div>
        </>
    );
}

export default Footer;
