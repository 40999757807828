import React from 'react';
import TopSection from "../TopSection/TopSection";
import Grid from "@material-ui/core/Grid";
import "../TopSection/TopSection.scss";
import MiddleSection from "../MiddleSection/MiddleSection";

const MainLandingPage = () => {

    return (
        <Grid item xs={12}>
            <TopSection/>
            <MiddleSection/>
        </Grid>
    );
}

export default MainLandingPage;