import React from 'react';
import TestimonialCarousel from "../Testimonial/TestimonialCarousel";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// CSS
import "./TopSection.scss";
import {Link} from "react-router-dom";

const LANDING_PAGE_STYLE = {
    backgroundColor: "rgba(4, 63, 140, 0.5)",
    color: "#FFF",
    borderRadius: "8px",
    padding: "15px",
    margin: "50px auto 0 auto"
};

const TopSection = () => {
    return (
        <div>
            <div id="landing-page-top-section">
                <div className='gridSection'>
                    <div className='testimonial'>
                        <TestimonialCarousel testimonialStyle={LANDING_PAGE_STYLE}/>
                    </div>
                    <Box className="buttonArea">
                        <Link to="/courses"><Button className='button' variant={"contained"} color="primary"><h5>Zu den
                            Bildungsangeboten</h5></Button></Link>
                    </Box>
                    <div className="bounceDiv">
                        <div className="bounceIcon bounce"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopSection;
