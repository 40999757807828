import React, {Component} from "react"
import {connect} from "react-redux"
import {compareTwoStrings} from "string-similarity"
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import "./CourseRecommendations.scss"

function mapStateToProps(state, props) {
    return {
        courses: state.courses.courses,
        courseId: props.id,
    }
}

class CourseRecommendations extends Component {
    constructor(props, context) {
        super(props, context);
        this.recommendedCourses = this.getSimilarCourses()
    }

    getSimilarCourses() {
        let referenceCourse = this.props.courses.filter(course => course.course.id === parseInt(this.props.courseId))[0]
        let courseScores = {}

        console.log("Start similar Courses")

        this.props.courses.forEach(course => {
            if (course.course.id !== parseInt(this.props.courseId)) {
                courseScores[course.course.id] = compareTwoStrings(referenceCourse.description, course.description)
            }
        })

        let items = Object.keys(courseScores).map(function (key) {
            return [key, courseScores[key]];
        });

        items.sort(function (first, second) {
            return second[1] - first[1];
        });

        return items.slice(0, 6);
    }

    render() {
        this.recommendedCourses = this.getSimilarCourses()

        if (this.recommendedCourses !== []) {
            return (
                <Grid container className="recommendation-container page-only" spacing={4}>
                    <Grid item xs={12}><h5>Empfehlungen</h5></Grid>
                    {
                        this.recommendedCourses.map(recommendedCourse => {
                            const courseData = this.props.courses.filter(course => course.course.id === parseInt(recommendedCourse[0]))[0]
                            return (
                                <Grid item xs={6} md={3} lg={3} xl={2}>
                                    <Card className="recommendation-card">
                                        <Link to={"/courses/" + courseData.course.id}>
                                            <CardActionArea>
                                                <CardMedia
                                                    className="media-card"
                                                    component="img"
                                                    alt="logo"
                                                    height="80"
                                                    image={courseData.course.featured_image}
                                                    title=""
                                                />
                                                <CardContent className="course-text">
                                                    <Typography className="course-title" gutterBottom>
                                                        {courseData.course.name}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
            )
        } else {
            return ("");
        }

    }
}

export default connect(mapStateToProps)(CourseRecommendations);