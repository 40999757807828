import {FETCH_PAGES_ERROR, FETCH_PAGES_PENDING, FETCH_PAGES_SUCCESS} from "../constants";

const initialState = {
    pending: false,
    pages: [],
    error: null
}

export function pages(state = initialState, action) {
    switch (action.type) {
        case FETCH_PAGES_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_PAGES_SUCCESS:
            return {
                ...state,
                pending: false,
                pages: action.pages
            }
        case FETCH_PAGES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}