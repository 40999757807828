import {WOOCOMMERCE_PURCHASE_URL} from "../constants";

import {resetCart, sendOrderError, sendOrderPending, sendOrderSuccess} from "./index";

function sendRegistration(registerInfo) {
    return dispatch => {
        dispatch(sendOrderPending());

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic " + btoa('ck_a73729831c434f3e06f0683adabecb628196ca50:cs_c44eb9d7ebc3e66c0b8f22634bef9b20383e00a6'));

        let raw = JSON.stringify(registerInfo);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(WOOCOMMERCE_PURCHASE_URL + "orders", requestOptions)
            .then(response => response.json())
            .then(httpResponse => {
                if (httpResponse.ok) {
                    dispatch(sendOrderSuccess(httpResponse.ok));
                    dispatch(resetCart());
                    dispatch(sendOrderError(!httpResponse.ok));
                } else {
                    httpResponse.json().then(res => {
                        dispatch(sendOrderError(true, res.code))
                    })
                }
            })
            .catch(error => {
                dispatch(sendOrderError("error"));
            });
    }
}

export default sendRegistration;