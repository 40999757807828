import React, {Component} from 'react';
import Testimonial from "./Testimonial";

// CSS
import "./Testimonial.scss";
import {connect} from "react-redux";
import {storeNextTestimonial} from "../../../actions";

const timeout = 20000;

function mapStateToProps(state) {
    return {
        testimonials: state.testimonials.testimonials,
        pending: state.testimonials.pending,
        current: state.testimonials.current,
        next: state.testimonials.next,
        timeOutId: state.testimonials.timeOutId
    }
}

let testimonials = {};
let intervalID

class TestimonialCarousel extends Component {

    setNext() {
        if (this.props.testimonials !== undefined) {
            testimonials.current = this.props.next;
            testimonials.next = (this.props.next + 1) % this.props.testimonials.length;
        }
        storeNextTestimonial(testimonials);
    }

    setIntervall() {
        intervalID = setInterval(() => this.setNext(), timeout);
    }

    componentWillUnmount() {
        this.setNext();
        clearInterval(intervalID)
    }

    componentDidMount() {
        this.setIntervall()
    }

    render() {
        if (this.props.testimonials.length > 0) {

            let currentTestimonial = this.props.testimonials[this.props.current];

            return (
                <div style={this.props.testimonialStyle}>
                    <Testimonial key={currentTestimonial.id} testimonial={currentTestimonial}/>
                </div>
            );
        }
        return null

    }
}

export default connect(mapStateToProps)(TestimonialCarousel);

