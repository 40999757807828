import {BASE_URL} from "../constants";

import {fetchPostsError, fetchPostsPending, fetchPostsSuccess} from "./index";

function fetchPosts() {
    return dispatch => {
        dispatch(fetchPostsPending());
        fetch(BASE_URL + "posts")
            .then(res => res.json())
            .then(res => {
                if (res.data !== undefined) {
                    throw(res.message);
                }
                dispatch(fetchPostsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchPostsError(error));
            })
    }
}

export default fetchPosts;