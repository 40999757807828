import React from 'react';

// CSS
import "./LogoBar.scss";
import sizIcon from "../../assets/images/siz_icon.png";
import ecdlIcon from "../../assets/images/ECLD_logoFooter.png";
import kvIcon from "../../assets/images/KV_logoFooter.png";
import kgvIcon from "../../assets/images/KGV_logoFooter.png";
import Grid from "@material-ui/core/Grid";

const LogoBar = () => {
    return (
        <Grid className="logo-bar-container" justify="space-around" alignItems="center"
              alignContent="space-around"
              container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
                <div className="image-container">
                    <a href="https://www.siz.ch" target="_blank" rel="noopener noreferrer">
                        <img id="siz-icon" src={sizIcon}
                             alt="SIZ Icon"/></a>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="image-container">
                    <a href="https://www.ecdl.ch" target="_blank" rel="noopener noreferrer">
                        <img
                            src={ecdlIcon}
                            alt="ECDL Logo"/></a>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="image-container">
                    <a href="https://www.kfmv.ch/" target="_blank" rel="noopener noreferrer">
                        <img src={kvIcon}
                             alt="KV Icon"/></a>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <div className="image-container">
                    <a href="https://www.gewerbe-sh.ch" target="_blank" rel="noopener noreferrer">
                        <img src={kgvIcon}
                             alt="KGV Icon"/></a>
                </div>
            </Grid>
        </Grid>


    );
};

export default LogoBar;
