import {
    FETCH_TESTIMONIALS_ERROR,
    FETCH_TESTIMONIALS_PENDING,
    FETCH_TESTIMONIALS_SUCCESS,
    STORE_NEXT_TESTIMONIAL
} from "../constants";

const initialState = {
    pending: false,
    testimonials: [],
    error: null,
    current: 0,
    next: 1
}

export function testimonials(state = initialState, action) {
    switch (action.type) {
        case FETCH_TESTIMONIALS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                pending: false,
                testimonials: action.testimonials
            }
        case FETCH_TESTIMONIALS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case STORE_NEXT_TESTIMONIAL:
            return {
                ...state,
                current: action.current,
                next: action.next,
            }
        default:
            return state;
    }
}
