import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from "@material-ui/core/Grid";

// CSS
import "./CartCards.scss";
import {connect} from "react-redux";
import {Button, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import {removeItemFromOrder, updateItemQuantity} from "../../actions";

const mapStateToProps = state => ({
    order: state.order,
});

class CartCards extends Component {

    constructor(props) {
        super(props);
        this.handleRemoveFromCartClick = this.handleRemoveFromCartClick.bind(this);
        this.updateChangeItemQuantity = this.updateChangeItemQuantity.bind(this);
    }

    handleRemoveFromCartClick(event, productId) {
        removeItemFromOrder(productId);
    }

    updateChangeItemQuantity(event, productId, newQuantity) {
        updateItemQuantity(productId, newQuantity);
    }

    render() {
        let cart = this.props.order.cart;

        if (cart.length > 0) {
            return (
                cart.map(cartItem => {
                    return (
                        <Grid container direction="row" justify="flex-start" alignItems="center"
                              key={cartItem.linkedCourse.id}>
                            <Card className="image-card">
                                <CardMedia
                                    className="media-card"
                                    component="img"
                                    alt="logo"
                                    height="200"
                                    image={cartItem.linkedCourse.course.featured_image}
                                    title=""
                                />
                                <CardContent className="course-text">
                                    <div className="item-title-description">
                                        <Typography className="course-title" gutterBottom variant="h5"
                                                    component="h5">
                                            {cartItem.linkedCourse.course.name}
                                        </Typography>
                                        <Typography className="course-short-description" variant="body1"
                                                    color="textSecondary"
                                                    component="p">
                                            {("Kurs startet am " + (new Date(cartItem.linkedCourse.course["start_date"] * 1000)).toLocaleDateString())}
                                        </Typography>
                                        <div className="item-price-quantity">
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography gutterBottom variant="h6" component="h6">
                                                        {("Anzahl: x " + cartItem.quantity)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <div className="item-quantity">
                                                        <IconButton className="item-quantity"
                                                                    disabled={(cartItem.quantity <= 1)}
                                                                    aria-label="minus" size="small"
                                                                    color="primary" onClick={e => {
                                                            this.updateChangeItemQuantity(e, cartItem.id, cartItem.quantity - 1)
                                                        }}>
                                                        </IconButton>
                                                        <RemoveIcon/>
                                                        <IconButton aria-label="plus" size="small" color="primary"
                                                                    onClick={e => {
                                                                        this.updateChangeItemQuantity(e, cartItem.id, cartItem.quantity + 1)
                                                                    }}>
                                                        </IconButton>
                                                        <AddIcon/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className="item-prize-sum" gutterBottom variant="h6"
                                                                component="h6">
                                                        {("CHF " + (cartItem.linkedProduct["regular_price"] * cartItem.quantity))}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <div className="additional-user-actions">
                                        <Link to={"/courses/" + cartItem.linkedCourse.course.id}>
                                            <Button className="course-detail-button">Mehr erfahren</Button>
                                        </Link>

                                        <Button className="course-remove-button" onClick={e => {
                                            this.handleRemoveFromCartClick(e, cartItem.id)
                                        }}>Entfernen</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })
            );
        }
    }
}

export default connect(mapStateToProps)(CartCards);