import React, {Component} from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import {connect} from "react-redux";

import "./ShoppingCart.scss";

const mapStateToProps = state => ({
    cart: state.order.cart
});


class ShoppingCart extends Component {
    render() {
        if (this.props.cart.length > 0) {
            return (
                <div className="shopping-cart-icon">
                    <ShoppingCartIcon/>
                    <div className="item-count">
                        <span>
                        {
                            this.props.cart.reduce((previousSum, currentCartItem) => previousSum + currentCartItem.quantity, 0)
                        }
                        </span>
                    </div>
                </div>
            );
        } else {
            return <span/>;
        }
    }
}

export default connect(mapStateToProps)(ShoppingCart);
