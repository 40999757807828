import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import MuiPhoneInput from "material-ui-phone-number";
import Button from "@material-ui/core/Button";
import {EMAILJS} from "../../constants"
import CustomizedSnackbar from "../../components/misc/CustomizedSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Controller, useForm} from "react-hook-form";

const parameters = {
    user_id: EMAILJS.userId,
    service_id: EMAILJS.serviceId,
    template_id: EMAILJS.templateId,
    template_params: {
        message: '',
        gender: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    }
}

const Form = () => {
    const [params, setParams] = useState(() => {
        return parameters
    })

    const [submitted, setSubmitted] = useState(() => {
        return false
    })

    const [acknowledgeReceived, setAcknowledgeReceived] = useState(() => {
        return false
    })

    const [errorReceived, setErrorReceived] = useState(() => {
        return false
    })

    const {control, register, handleSubmit, errors} = useForm();

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setParams({...params, template_params: {...params.template_params, [name]: value}})
    }


    const handlePhoneFieldChange = (event) => {
        setParams({...params, template_params: {...params.template_params, phone: event}})
    }

    const resetTemplateParams = () => {
        setParams({
            ...params,
            template_params: {
                ...params.template_params,
                message: '',
                gender: '',
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
            }
        })
    }

    const onSubmit = () => {
        setSubmitted(true);
        setErrorReceived(false);
        setAcknowledgeReceived(false);
        sendEmail();
        return true;
    }

    const sendEmail = () => {
        console.log(params)
        const options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        fetch(EMAILJS.api, options)
            .then((httpResponse) => {
                if (httpResponse.ok) {
                    setAcknowledgeReceived(true)
                    resetTemplateParams()
                } else {
                    setErrorReceived(true);
                    setSubmitted(false);
                    return httpResponse.text()
                        .then(text => Promise.reject(text));
                }
            })
            .catch(() => {
                setErrorReceived(true);
                setSubmitted(false)
            });
    }


    if (!acknowledgeReceived) {
        return (
            <div>
                {errorReceived &&
                <CustomizedSnackbar key={String(errorReceived)} errorReceived={errorReceived} open={true}/>}
                <h6>Wir freuen uns über Ihre Nachricht</h6>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid item xs={12}>
                        <FormControl component="fieldset" value={params.template_params.gender}
                                     onChange={handleChange}>
                            <FormLabel required component="legend">Anrede</FormLabel>
                            <RadioGroup aria-label="gender" name="gender">
                                <FormControlLabel value='Frau' control={<Radio/>} label="Frau"/>
                                <FormControlLabel value='Herr' control={<Radio/>} label="Herr"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>


                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="firstName"
                                as={
                                    <TextField
                                        ref={register}
                                        className="contact-form"
                                        name="firstName"
                                        label="Vorname*"
                                        variant="outlined"
                                        helperText={errors.firstName ? errors.firstName.message : null}
                                        error={errors.firstName && true}
                                        onChange={handleChange}
                                    />
                                }
                                control={control}
                                defaultValue={params.template_params.firstName}
                                rules={{
                                    required: 'Bitte geben Sie Ihren Vornamen ein',
                                    maxLength: {
                                        value: 30
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="lastName"
                                as={
                                    <TextField
                                        ref={register}
                                        className="contact-form"
                                        name="lastName"
                                        label="Nachname*"
                                        variant="outlined"
                                        helperText={errors.lastName ? errors.lastName.message : null}
                                        error={errors.lastName && true}
                                        onChange={handleChange}
                                    />
                                }
                                control={control}
                                defaultValue={params.template_params.lastName}
                                rules={{
                                    required: 'Bitte geben Sie Ihren Nachnamen ein',
                                    maxLength: {
                                        value: 30
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                as={
                                    <TextField
                                        ref={register}
                                        className="contact-form"
                                        name="email"
                                        label="E-Mail*"
                                        variant="outlined"
                                        helperText={errors.email ? errors.email.message : null}
                                        error={errors.email && true}
                                        onChange={handleChange}
                                    />
                                }
                                control={control}
                                defaultValue={params.template_params.email}
                                rules={{
                                    required: 'Bitte geben Sie eine E-Mail Adresse ein',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Bitte geben Sie eine gültige E-Mail Adresse ein'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="phone"
                                as={
                                    <MuiPhoneInput
                                        ref={register}
                                        className="contact-form"
                                        name="phone"
                                        label="Telefon*"
                                        variant="outlined"
                                        onlyCountries={['ch']}
                                        defaultCountry={'ch'}
                                        onChange={handlePhoneFieldChange}
                                        helperText={errors.phone ? errors.phone.message : null}
                                        error={errors.phone && true}
                                    />
                                }
                                control={control}
                                defaultValue={params.template_params.phone}
                                rules={{
                                    required: 'Bitte geben Sie Ihre Telefonnummer ein',
                                    minLength: {
                                        value: 5,
                                        message: 'Bitte geben Sie eine gültige Telefonnummer ein'
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: 'Bitte geben Sie eine gültige Telefonnummer ein'
                                    }
                                }}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Controller
                                name="message"
                                as={
                                    <TextField
                                        ref={register}
                                        multiline
                                        rows={5}
                                        maxows={15}
                                        className="contact-form"
                                        name="message"
                                        label="Mitteilung*"
                                        variant="outlined"
                                        helperText={errors.message ? errors.message.message : null}
                                        error={errors.message && true}
                                        onChange={handleChange}
                                    />
                                }
                                control={control}
                                defaultValue={params.template_params.message}
                                rules={{
                                    required: 'Bitte geben Sie eine Nachricht ein',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {!submitted ? <Button color="primary" variant="contained" type="submit"
                                                  value="send"> Absenden </Button> :
                                <CircularProgress thickness={5}/>}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    } else if (acknowledgeReceived) {
        return (
            <div>
                <CustomizedSnackbar acknowledgeReceived={acknowledgeReceived} open={true}/>
                <h6> Vielen Dank für Ihre Nachricht. Wir werden uns in
                    Kürze bei Ihnen melden.</h6>
            </div>
        )
    }
}

export default Form;