import Snackbar from '@material-ui/core/Snackbar';
import React, {Component} from 'react';
import {Alert} from '@material-ui/lab';

class CustomizedSnackbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acknowledgeReceived: this.props.acknowledgeReceived,
            errorReceived: this.props.errorReceived,
            open: this.props.open,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    render() {
        let {open, errorReceived, acknowledgeReceived} = this.state;
        if (acknowledgeReceived) {
            return (
                <div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="success">
                            Nachricht erfolgreich übermittelt!
                        </Alert>
                    </Snackbar>
                </div>
            )
        } else if (errorReceived) {
            return (
                <div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="error">
                            Nachricht konnte nicht übermittelt werden!
                        </Alert>
                    </Snackbar>
                </div>
            )
        } else return (<></>)
    };
}

export default CustomizedSnackbar;
