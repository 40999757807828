import React from 'react';
import {Typography} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";

// CSS
import "./ContactAddress.scss";

const ContactAddress = () => {
    return (
        <div className="contact-form-address-container">
            <Typography className="text"><strong>compucollege</strong></Typography>
            <Typography> Schützengraben 20</Typography>
            <Typography>CH-8200 Schaffhausen</Typography>
            <span className="iconLine"><MailIcon className="icon"/><Typography className="link-text"
            ><a
                href="mailto:info@compucollege.ch">info@compucollege.ch</a></Typography></span>
            <span className="iconLine"><PhoneIcon className="icon"/><Typography className="link-text"
            ><a href="tel://+41526202880">+41 52 620 28 80</a></Typography></span>
        </div>
    );
};

export default ContactAddress;
