import React from 'react';
import {Link, NavLink} from 'react-router-dom';

// Material-UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from "@material-ui/core/Button";
import logo from '../../assets/images/compucollege_logo.jpg';

// CSS
import "./Header.scss";
import ShoppingCart from "../shoppingCart/ShoppingCart";

const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="limitwidth-container header">
            <AppBar style={{boxShadow: "none", backgroundColor: "white"}} position={"static"} color={"default"}>
                <Toolbar className="toolbar">
                    <div className="section-mobile">
                        <Link className="shopping-cart-mobile-icon" to="/checkout"><ShoppingCart/></Link>
                    </div>
                    <Link to="/"><img className="image" src={logo} alt={"logo"}/></Link>
                    <div className="right-div">
                        <div className="section-mobile">
                            <IconButton edge="start" className="menu-button" color="inherit" aria-label="menu"
                                        onClick={handleMenu}>
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <NavLink exact to="/" activeClassName="active"><MenuItem
                                    onClick={handleClose}>Home</MenuItem></NavLink>
                                <NavLink to="/courses" activeClassName="active"><MenuItem
                                    onClick={handleClose}>Angebot</MenuItem></NavLink>
                                <NavLink to="/business-courses" activeClassName="active"><MenuItem
                                    onClick={handleClose}>Firmen</MenuItem></NavLink>
                                <NavLink to="/contact" activeClassName="active"><MenuItem
                                    onClick={handleClose}>Kontakt</MenuItem></NavLink>
                                <NavLink to="/about" activeClassName="active"><MenuItem
                                    onClick={handleClose}>Unternehmen</MenuItem></NavLink>
                            </Menu>
                        </div>
                        <div className="section-desktop">
                            <NavLink exact to="/" activeClassName="active"><Button
                                className="button">Home</Button></NavLink>
                            <NavLink to="/courses" activeClassName="active"><Button className="button">Angebot</Button></NavLink>
                            <NavLink to="/business-courses" activeClassName="active"><Button
                                className="button">Firmen</Button></NavLink>
                            <NavLink to="/contact" activeClassName="active"><Button className="button">Kontakt</Button></NavLink>
                            <NavLink to="/about" activeClassName="active"><Button
                                className="button">Unternehmen</Button></NavLink>
                            <Link to="/checkout"><ShoppingCart/></Link>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
