import reducer from "./reducers";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

const store = createStore(reducer, composeWithDevTools(
    applyMiddleware(promise, thunk)
));

export default store;