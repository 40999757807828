import React, {Component} from "react";

import Collapse from '@material-ui/core/Collapse';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CropFreeIcon from '@material-ui/icons/CropFree';

import "./ContactButton.scss";
import vcardQRCode from "../../assets/images/vcard-qrcode.svg";

class ContactButton extends Component {
    constructor(props) {
        super(props);
        this.state = {contactOpen: false, qrcodeOpen: false};
        this.handleContactClick = this.handleContactClick.bind(this);
        this.handleQRCodeClick = this.handleQRCodeClick.bind(this);
    }

    handleContactClick() {
        this.setState({contactOpen: !this.state.contactOpen, qrcodeOpen: false});
    }

    handleQRCodeClick() {
        if (this.state.contactOpen) {
            this.setState({qrcodeOpen: !this.state.qrcodeOpen});
        }
    }

    render() {
        return (
            <div className="contact-button">
                <Collapse className="contact-collapse" in={this.state.contactOpen} timeout="auto" unmountOnExit>
                    <CropFreeIcon onClick={this.handleQRCodeClick}/>
                    <Collapse in={this.state.qrcodeOpen} timeout="auto" unmountOnExit>
                        <div id="qrcode-background" onClick={this.handleQRCodeClick}>
                            <div id="qrcode">
                                <h3>QR-Code</h3>
                                <h5>Scannen Sie den QR-Code mit der Kamera Ihres Mobiltelefons, um compucollege als
                                    Kontakt hinzuzufügen.</h5><br/>
                                <img src={vcardQRCode} width="250" height="250" alt="vCard QR-Code"/>
                            </div>
                        </div>
                    </Collapse>
                    <a href="tel://+41526202880"><PhoneIcon/></a>
                    <a href="mailto:info@compucollege.ch"><EmailIcon/></a>
                    <a href="https://wa.me/41787371602" target="_blank" rel="noopener noreferrer"><WhatsAppIcon/></a>
                </Collapse>
                <ContactSupportIcon id="contact-support-button" onClick={this.handleContactClick}/>
            </div>
        )
    }
}

export default ContactButton;