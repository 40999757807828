import React, {Component} from 'react';

// CSS
import "./LandingpostSection.scss";

import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

function mapStateToProps(state) {
    return {
        landingposts: state.landingposts.landingposts,
    }
}

class LandingpostSection extends Component {

    render() {

        if (!this.props.landingposts || !this.props.landingposts.length > 0) {
            return (<CircularProgress/>);
        }

        return (
            this.props.landingposts.map((landingpost, i) => {
                let imageContainer = landingpost.excerpt.rendered;
                let imageLink = imageContainer.substr(3, imageContainer.length - 8);

                if (i % 2 === 0){
                    return (
                        <Grid item key={i}>
                            <Grid container direction="row">
                                <Grid item xs>
                                    <Grid container direction="column">
                                        <Typography variant="h4"
                                                    className="subtitle">{landingpost.title.rendered}</Typography>
                                        <Typography dangerouslySetInnerHTML={{__html: landingpost.content.rendered}}
                                                    variant="h6"
                                                    className="title"/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className="post-icon" style={{backgroundImage: "url(" + imageLink + ")"}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item key={i}>
                            <Grid container direction="row">
                                <Grid item xs={3}>
                                    <div className="post-icon" style={{backgroundImage: "url(" + imageLink + ")"}}/>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction="column">
                                        <Typography variant="h4"
                                                    className="subtitle">{landingpost.title.rendered}</Typography>
                                        <Typography dangerouslySetInnerHTML={{__html: landingpost.content.rendered}}
                                                    variant="h6"
                                                    className="title"/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }
            }));
    }
}

export default connect(mapStateToProps)(LandingpostSection);

