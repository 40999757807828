import React, {Component} from 'react';
import Fade from "@material-ui/core/Fade";


class Testimonial extends Component {


    render() {

        const testimonial = this.props.testimonial;

        let content = "";
        let testimonialAuthor = "";

        if (testimonial) {

            if (testimonial.content && testimonial.content.rendered) {
                content = testimonial.content.rendered;
            }

            if (testimonial.title && testimonial.title.rendered) {
                testimonialAuthor = testimonial.title.rendered;
            }
        }

        return (
            <Fade timeout={3000} direction="right" in={true} mountOnEnter unmountOnExit>
                <div>
                    <h5 dangerouslySetInnerHTML={{__html: content}}
                        className="testimonial-text"/>
                    <h6 className="testimonial-author">{testimonialAuthor}</h6>
                </div>
            </Fade>
        );
    }
}

export default Testimonial;