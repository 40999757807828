import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import compucollegeImage from "../../assets/images/compucollege-4.jpg"
import TestimonialCarousel from "../landingPage/Testimonial/TestimonialCarousel";

const mapStateToProps = state => ({
    pages: state.pages.pages
});


class MainAboutPage extends Component {
    render() {
        let aboutPage = this.props.pages.filter(page => page.id === 440);
        if (aboutPage.length === 1) {
            return (

                <div className="limitwidth-container">
                    <Paper className="paper-testimonial">
                        <TestimonialCarousel/>
                    </Paper>
                    <Paper className="paper-background">
                        <h3>Unternehmen</h3>
                        <img className="compucollege-image" src={compucollegeImage} width="5936" height="2369"
                             alt="compucollege"/>
                        <div>
                            <Grid item xs={12} dangerouslySetInnerHTML={{__html: aboutPage[0].content.rendered}}/>
                        </div>
                    </Paper>
                </div>

            );
        } else {
            return (
                <div className="limitwidth-container">
                    <CircularProgress/>
                </div>
            );
        }
    }
}

export default connect(mapStateToProps)(MainAboutPage);