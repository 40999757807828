import React from 'react';

import "./MainContactPage.scss";
import ContactForm from "./ContactForm";
import TestimonialCarousel from "../landingPage/Testimonial/TestimonialCarousel";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const MainContactPage = () => {
    const [state, setState] = React.useState({
        open: true,
        vertical: 'top',
        horizontal: 'center'
    });

    const {vertical, horizontal, open} = state;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({...state, open: false});
    };

    return (
        <div id="main-contact-page" className="limitwidth-container">
            <Paper className="paper-testimonial">
                <TestimonialCarousel/>
            </Paper>
            <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={15000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">
                    Haben Sie unseren Hilfe-Button bereits gesehen? Er befindet sich in der rechten unteren Ecke.
                </Alert>
            </Snackbar>
            <ContactForm/>
        </div>
    );
}

export default MainContactPage;