import React, {Component} from 'react';
import "./ContactForm.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {EMBED_MAP_URL} from "../../constants"
import Form from "./Form";
import compucollegeImage from "../../assets/images/compucollege-2.jpg";
import ContactAddress from "./ContactAddress";

class ContactForm extends Component {

    render() {
        return (
            <div>
                <Paper className="paper-background">
                    <h3>So erreichen Sie uns</h3>
                    <img className="compucollege-image" src={compucollegeImage} width="5936" height="2369"
                         alt="compucollege"/>
                    <ContactAddress/>
                    <Form/>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div>
                                {
                                    <iframe
                                        title="maps"
                                        className="maps"
                                        src={EMBED_MAP_URL}>
                                    </iframe>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default ContactForm;