import {FETCH_POSTS_ERROR, FETCH_POSTS_PENDING, FETCH_POSTS_SUCCESS} from "../constants";

const initialState = {
    pending: false,
    posts: [],
    error: null
}

export function posts(state = initialState, action) {
    switch (action.type) {
        case FETCH_POSTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_POSTS_SUCCESS:
            return {
                ...state,
                pending: false,
                posts: action.posts
            }
        case FETCH_POSTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}