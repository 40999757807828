import {WOOCOMMERCE_URL} from "../constants";

import {fetchProductsError, fetchProductsPending, fetchProductsSuccess} from "./index";

function fetchProducts() {
    return dispatch => {
        dispatch(fetchProductsPending());

        fetch(WOOCOMMERCE_URL + "products?per_page=100")
            .then(res => res.json())
            .then(res => {
                if (res.data !== undefined) {
                    throw(res.message);
                }
                dispatch(fetchProductsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchProductsError(error));
            })
    }
}

export default fetchProducts;