import {WPLMS_URL} from "../constants";

import {fetchCoursesError, fetchCoursesPending, fetchCoursesSuccess} from "./index";

function fetchCourses() {
    return dispatch => {
        dispatch(fetchCoursesPending());
        fetch(WPLMS_URL + "Course")
            .then(res => res.json())
            .then(res => {
                if (res.message !== undefined) {
                    throw(res.message);
                }

                let courses = []
                let counter = 0;
                res.forEach(function (course) {
                    fetch(WPLMS_URL + "Course/" + course.data.id)
                        .then(response => response.json())
                        .then(response => {
                            if (response.message !== undefined) {
                                throw(response.message);
                            }
                            courses.push(response[0].data);
                            counter++;
                        })
                        .catch(e => {
                            console.log("An error occured " + e)
                        })
                        .then(() => {
                            if (counter >= res.length) {
                                dispatch(fetchCoursesSuccess(courses));
                                return courses;
                            }
                        })
                });


            })
            .catch(error => {
                dispatch(fetchCoursesError(error));
            })
    }
}

export default fetchCourses;