import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MuiPhoneInput from "material-ui-phone-number";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Controller, useForm} from "react-hook-form";
import sendRegistration from "../../actions/registration";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import agb from "../../assets/downloads/agb.pdf";

import {
    updateBillingRegistrationFormFieldCity,
    updateBillingRegistrationFormFieldFirstName,
    updateBillingRegistrationFormFieldLastName,
    updateBillingRegistrationFormFieldPostCode,
    updateBillingRegistrationFormFieldStreet,
    updateBillingRegistrationFormFieldStreetNumber,
    updateIsShippingAddressRequired,
    updateRegistrationFormFieldAGBaccepted,
    updateRegistrationFormFieldCouponLines,
    updateRegistrationFormFieldCustomerNote,
    updateRegistrationFormFieldEmail,
    updateRegistrationFormFieldPhone,
    updateShippingRegistrationFormFieldCity,
    updateShippingRegistrationFormFieldFirstName,
    updateShippingRegistrationFormFieldLastName,
    updateShippingRegistrationFormFieldPostCode,
    updateShippingRegistrationFormFieldStreet,
    updateShippingRegistrationFormFieldStreetNumber
} from "../../actions";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const RegistrationForm = (props) => {

    const {trigger, errors, control, register, handleSubmit} = useForm();

    const onSubmit = () => {
        props.sendRegistration(buildRegistrationObject());
        return true;
    }

    const handleIsShippingAddressRequiredChange = () => {
        props.isShippingAddressRequired ? updateIsShippingAddressRequired(false) : updateIsShippingAddressRequired(true)
    }

    const handleAreAGBacceptedChange = () => {
        props.AGBaccepted ? updateRegistrationFormFieldAGBaccepted(false) : updateRegistrationFormFieldAGBaccepted(true)
    }

    const buildRegistrationObject = () => {
        const line_items = [];
        props.cart.map(item => {
            line_items.push({product_id: item.id, quantity: item.quantity})
            return true;
        });
        return {
            "payment_method": "bacs",
            "payment_method_title": "Direct Bank Transfer",
            "set_paid": true,
            "customer_note": props.customerNote,
            "coupon_lines": props.couponLines !== "" ? [{"code": props.couponLines}] : "",
            "billing": {
                "first_name": props.billingFirstName,
                "last_name": props.billingLastName,
                "address_1": props.billingStreet + " " + props.billingStreetNumber,
                "address_2": "",
                "city": props.billingCity,
                "state": props.billingCity,
                "postcode": props.billingPostcode,
                "country": "CH",
                "email": props.email,
                "phone": props.phone,
            },
            "shipping": {
                "first_name": props.shippingFirstName,
                "last_name": props.shippingLastName,
                "address_1": props.shippingStreet + " " + props.shippingStreetNumber,
                "address_2": "",
                "city": props.shippingCity,
                "state": props.shippingCountry,
                "postcode": props.shippingPostcode,
                "country": "CH"
            },
            "line_items": line_items
        }
    }

    if (!props.success) {
        return (
            <div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h6>Rechnungsadresse</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="billingFirstName"
                                control={control}
                                defaultValue={props.billingFirstName}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: "Bitte geben Sie Ihren Vornamen ein",
                                            maxLength: 30
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="Vorname*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldFirstName(e.target.value)
                                        }}
                                        helperText={errors.billingFirstName ? errors.billingFirstName.message : null}
                                        error={errors.billingFirstName && true}
                                    />
                                )}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="billingLastName"
                                control={control}
                                defaultValue={props.billingLastName}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: "Bitte geben Sie Ihren Nachnamen ein",
                                            maxLength: 30
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="Nachname*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldLastName(e.target.value)
                                        }}
                                        helperText={errors.billingLastName ? errors.billingLastName.message : null}
                                        error={errors.billingLastName && true}
                                    />
                                )}

                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="billingStreet"
                                control={control}
                                defaultValue={props.billingStreet}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: "Bitte geben Sie Ihre Strasse ein",
                                            maxLength: 50
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="Strasse*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldStreet(e.target.value)
                                        }}
                                        helperText={errors.billingStreet ? errors.billingStreet.message : null}
                                        error={errors.billingStreet && true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="billingStreetNumber"
                                control={control}
                                defaultValue={props.billingStreetNumber}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: " ",
                                            maxLength: 5
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="Nr.*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldStreetNumber(e.target.value)
                                        }}
                                        helperText={errors.billingStreetNumber ? errors.billingStreetNumber.message : null}
                                        error={errors.billingStreetNumber && true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="billingPostcode"
                                control={control}
                                defaultValue={props.billingPostcode}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: "Bitte geben Sie die Postleitzahl ein",
                                            pattern: {
                                                value: /^([1-468][0-9]|[57][0-7]|9[0-6])[0-9]{2}$/,
                                                message: 'Bitte geben Sie eine gültige Postleitzahl ein'

                                            }
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="PLZ*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldPostCode(e.target.value)
                                        }}
                                        helperText={errors.billingPostcode ? errors.billingPostcode.message : null}
                                        error={errors.billingPostcode && true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="billingCity"
                                control={control}
                                defaultValue={props.billingCity}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({required: "Bitte geben Sie die Stadt ein", maxLength: 10})}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="Stadt*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateBillingRegistrationFormFieldCity(e.target.value)
                                        }}
                                        helperText={errors.billingCity ? errors.billingCity.message : null}
                                        error={errors.billingCity && true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={props.email}
                                render={({name, value}) => (
                                    <TextField
                                        inputRef={register({
                                            required: "Bitte geben Sie Ihre E-Mail Adresse ein",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Bitte geben Sie eine gültige E-Mail Adresse ein'
                                            }
                                        })}
                                        className="contact-form"
                                        name={name}
                                        defaultValue={value}
                                        label="E-Mail*"
                                        variant="outlined"
                                        onChange={e => {
                                            updateRegistrationFormFieldEmail(e.target.value)
                                        }}
                                        helperText={errors.email ? errors.email.message : null}
                                        error={errors.email && true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPhoneInput
                                className="contact-form"
                                variant="outlined"
                                onlyCountries={['ch', 'de']}
                                defaultCountry={'ch'}
                                name="phone"
                                defaultValue={props.phone}
                                label="Telefon"
                                onChange={e => {
                                    updateRegistrationFormFieldPhone(e)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                defaultValue={props.couponLines}
                                label="Gutscheincode"
                                variant="outlined"
                                onChange={e => {
                                    updateRegistrationFormFieldCouponLines(e.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                multiline
                                rows={5}
                                maxows={15}
                                name="customerNote"
                                label="Mitteilung"
                                variant="outlined"
                                defaultValue={props.customerNote}
                                onChange={(e) => {
                                    updateRegistrationFormFieldCustomerNote(e.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.isShippingAddressRequired}
                                        onChange={() => {
                                            handleIsShippingAddressRequiredChange()
                                        }}
                                        name="Lieferadresse"
                                        color="primary"
                                    />
                                }
                                label="Andere Lieferadresse angeben?"
                            />
                        </Grid>

                        {props.isShippingAddressRequired ?
                            <>
                                <Grid item xs={12}>
                                    <h6>Lieferadresse</h6>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="shippingFirstName"
                                        control={control}
                                        defaultValue={props.shippingFirstName}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: "Bitte geben Sie Ihren Vornamen ein",
                                                    maxLength: 30
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="Vorname*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldFirstName(e.target.value)
                                                }}
                                                helperText={errors.shippingFirstName ? errors.shippingFirstName.message : null}
                                                error={errors.shippingFirstName && true}
                                            />
                                        )}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="shippingLastName"
                                        control={control}
                                        defaultValue={props.shippingLastName}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: "Bitte geben Sie Ihren Nachnamen ein",
                                                    maxLength: 30
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="Nachname*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldLastName(e.target.value)
                                                }}
                                                helperText={errors.shippingLastName ? errors.shippingLastName.message : null}
                                                error={errors.shippingLastName && true}
                                            />
                                        )}

                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                        name="shippingLastName"
                                        control={control}
                                        defaultValue={props.shippingStreet}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: "Bitte geben Sie Ihre Strasse ein",
                                                    maxLength: 50
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="Strasse*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldStreet(e.target.value)
                                                }}
                                                helperText={errors.shippingLastName ? errors.shippingLastName.message : null}
                                                error={errors.shippingLastName && true}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        name="shippingStreetNumber"
                                        control={control}
                                        defaultValue={props.shippingStreetNumber}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: " ",
                                                    maxLength: 5
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="Nr.*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldStreetNumber(e.target.value)
                                                }}
                                                helperText={errors.shippingStreetNumber ? errors.shippingStreetNumber.message : null}
                                                error={errors.shippingStreetNumber && true}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="shippingPostcode"
                                        control={control}
                                        defaultValue={props.shippingPostcode}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: "Bitte geben Sie die Postleitzahl ein",
                                                    pattern: {
                                                        value: /^([1-468][0-9]|[57][0-7]|9[0-6])[0-9]{2}$/,
                                                        message: 'Bitte geben Sie eine gültige Postleitzahl ein'

                                                    }
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="PLZ*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldPostCode(e.target.value)
                                                }}
                                                helperText={errors.shippingPostcode ? errors.shippingPostcode.message : null}
                                                error={errors.shippingPostcode && true}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="shippingCity"
                                        control={control}
                                        defaultValue={props.shippingCity}
                                        render={({name, value}) => (
                                            <TextField
                                                inputRef={register({
                                                    required: "Bitte geben Sie die Stadt ein",
                                                    maxLength: 10
                                                })}
                                                className="contact-form"
                                                name={name}
                                                defaultValue={value}
                                                label="Stadt*"
                                                variant="outlined"
                                                onChange={e => {
                                                    updateShippingRegistrationFormFieldCity(e.target.value)
                                                }}
                                                helperText={errors.shippingCity ? errors.shippingCity.message : null}
                                                error={errors.shippingCity && true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                            : null}

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        required
                                        checked={props.AGBaccepted}
                                        onChange={() => {
                                            handleAreAGBacceptedChange()
                                        }}
                                        name="AGB"
                                        color="primary"
                                    />
                                }
                                label={<a href={agb} target="_blank" rel="noopener noreferrer"><p>Ich habe die
                                    Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese.</p></a>}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {!props.sending ? <Button onClick={() => {
                                    trigger().then();
                                }} color="primary" variant="contained" type="submit"
                                                      value="send"> Anmelden </Button> :
                                <CircularProgress thickness={5}/>}
                        </Grid>
                        {props.errorCode === "woocommerce_rest_invalid_coupon" && <Snackbar open={true}>
                            <Alert severity="error">
                                Der Gutschein-Code ist nicht gültig.
                            </Alert>
                        </Snackbar>}
                    </Grid>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.order.cart,
    billingFirstName: state.register.billingFirstName,
    billingLastName: state.register.billingLastName,
    billingStreet: state.register.billingStreet,
    billingStreetNumber: state.register.billingStreetNumber,
    billingCity: state.register.billingCity,
    billingState: state.register.billingState,
    billingPostcode: state.register.billingPostcode,
    billingCountry: state.register.billingCountry,
    email: state.register.email,
    phone: state.register.phone,
    customerNote: state.register.customerNote,
    couponLines: state.register.couponLines,
    AGBaccepted: state.register.AGBaccepted,
    success: state.register.success,
    sent: state.register.sent,
    error: state.register.error,
    errorCode: state.register.errorCode,
    sending: state.register.sending,
    isSnackbarOpen: state.register.isSnackbarOpen,

    isShippingAddressRequired: state.register.isShippingAddressRequired,
    shippingFirstName: state.register.shippingFirstName,
    shippingLastName: state.register.shippingLastName,
    shippingStreet: state.register.shippingStreet,
    shippingStreetNumber: state.register.shippingStreetNumber,
    shippingCity: state.register.shippingCity,
    shippingState: state.register.shippingState,
    shippingPostcode: state.register.shippingPostcode,
    shippingCountry: state.register.shippingCountry,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    sendRegistration: sendRegistration
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationForm);