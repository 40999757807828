import {BASE_URL} from "../constants";

import {fetchPagesError, fetchPagesPending, fetchPagesSuccess} from "./index";

function fetchPages() {
    return dispatch => {
        dispatch(fetchPagesPending());
        fetch(BASE_URL + "pages")
            .then(res => res.json())
            .then(res => {
                if (res.data !== undefined) {
                    throw(res.message);
                }
                dispatch(fetchPagesSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchPagesError(error));
            })
    }
}

export default fetchPages;