import {createMuiTheme} from "@material-ui/core";

const globalColors = {
    palette: {
        primary: {
            main: '#043f8c',
            light: '#4b69bd',
            dark: '#001a5e'
        },
        secondary: {
            main: '#b4babf',
            light: '#e6ecf2',
            dark: '#848a8f',
        }
    }
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#043f8c',
            light: '#4b69bd',
            dark: '#001a5e'
        },
        secondary: {
            main: '#b4babf',
            light: '#e6ecf2',
            dark: '#848a8f',
        }
    }
})

export {theme};
export default globalColors;