import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

const mapStateToProps = state => ({
    pages: state.pages.pages
});

class MainDataprotectionPage extends Component {
    render() {
        let aboutPage = this.props.pages.filter(page => page.id === 644);
        if (aboutPage.length === 1) {
            return (
                <div className="limitwidth-container">
                    <Paper className="paper-background">
                        <h3>Datenschutzerklärung</h3>
                        <div>
                            <Grid item xs={12} dangerouslySetInnerHTML={{__html: aboutPage[0].content.rendered}}/>
                        </div>
                    </Paper>
                </div>
            );
        } else {
            return (
                <div className="limitwidth-container">
                    <Paper className="paper-background">
                        <h3>Datenschutzerklärung</h3>
                        <div>
                            <CircularProgress/>
                        </div>
                    </Paper>
                </div>
            );
        }
    }
}

export default connect(mapStateToProps)(MainDataprotectionPage);