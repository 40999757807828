export const BASE_URL = 'https://compucollege.keke.ch/wp-json/wp/v2/';
export const WPLMS_URL = 'https://compucollege.keke.ch/wp-json/wplms/v1/';
export const WOOCOMMERCE_URL = 'https://compucollege.keke.ch/wp-json/public-woo/v1/';
export const WOOCOMMERCE_PURCHASE_URL = 'https://compucollege.keke.ch/wp-json/wc/v3/';

export const FETCH_PAGES_PENDING = 'FETCH_PAGES_PENDING';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_ERROR = 'FETCH_PAGES_ERROR';

export const FETCH_POSTS_PENDING = 'FETCH_POSTS_PENDING';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR';

export const FETCH_TESTIMONIALS_PENDING = 'FETCH_TESTIMONIALS_PENDING';
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS';
export const FETCH_TESTIMONIALS_ERROR = 'FETCH_TESTIMONIALS_ERROR';
export const STORE_NEXT_TESTIMONIAL = 'STORE_NEXT_TESTIMONIAL';

export const FETCH_LANDINGPOSTS_PENDING = 'FETCH_LANDINGPOSTS_PENDING';
export const FETCH_LANDINGPOSTS_SUCCESS = 'FETCH_LANDINGPOSTS_SUCCESS';
export const FETCH_LANDINGPOSTS_ERROR = 'FETCH_LANDINGPOSTS_ERROR';

export const FETCH_COURSES_PENDING = 'FETCH_COURSES_PENDING';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_ERROR = 'FETCH_COURSES_ERROR';
export const UPDATE_COURSE_TEXT_FILTER = 'UPDATE_COURSE_TEXT_FILTER';
export const UPDATE_COURSE_TECHNOLOGY_FILTER = 'UPDATE_COURSE_TECHNOLOGY_FILTER';
export const UPDATE_COURSE_TYPE_FILTER = 'UPDATE_COURSE_TYPE_FILTER';
export const UPDATE_COURSE_LEVEL_FILTER = 'UPDATE_COURSE_LEVEL_FILTER';

export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const RESET_CART = 'RESET_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const REGISTER_ORDER_PENDING = 'REGISTER_ORDER_PENDING';
export const REGISTER_ORDER_SUCCESS = 'REGISTER_ORDER_SUCCESS';
export const REGISTER_ORDER_ERROR = 'REGISTER_ORDER_ERROR';
export const RESET_REGISTRATION_STATES = 'RESET_REGISTRATION_STATES';
export const UPDATE_IS_SHIPPING_ADDRESS_REQUIRED = 'UPDATE_IS_SHIPPING_ADDRESS_REQUIRED';
export const UPDATE_REGISTRATION_CUSTOMER_NOTE = 'UPDATE_REGISTRATION_CUSTOMER_NOTE';
export const UPDATE_REGISTRATION_COUPON_LINES = 'UPDATE_REGISTRATION_COUPON_LINES';
export const UPDATE_REGISTRATION_FORM_SNACKBAR = 'UPDATE_REGISTRATION_FORM_SNACKBAR';

export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_FIRST_NAME = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_FIRST_NAME';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_LAST_NAME = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_LAST_NAME';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET_NUMBER = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET_NUMBER';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY';
export const UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY = 'UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_FIRST_NAME = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_FIRST_NAME';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_LAST_NAME = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_LAST_NAME';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET_NUMBER = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET_NUMBER';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_POSTCODE = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_POSTCODE';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STATE = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STATE';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_COUNTRY = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_COUNTRY';
export const UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_CITY = 'UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_CITY';

export const UPDATE_REGISTRATION_FORM_FIELD_EMAIL = 'UPDATE_REGISTRATION_FORM_FIELD_EMAIL';
export const UPDATE_REGISTRATION_FORM_FIELD_PHONE = 'UPDATE_REGISTRATION_FORM_FIELD_PHONE';
export const UPDATE_REGISTRATION_FORM_FIELD_AGB_ACCEPTED = 'UPDATE_REGISTRATION_FORM_FIELD_AGB_ACCEPTED';

export const SHOULD_SHOW_COURSE_POPUP = 'SHOULD_SHOW_COURSE_POPUP';

export const EMBED_MAP_URL = "https://www.google.com/maps/embed/v1/place?key=AIzaSyC93yZ5hE3TcnEQTFq306YCtvrtARtyfJ4&q=compucollege,Schaffhausen+Schweiz"
export const EMAILJS = {
    templateId: "template_3ipmcs8",
    userId: "user_u2AAvLOGGw0x7tTedBotL",
    accessToken: "92b0eee73abc771b6878963d932a7b54",
    serviceId: "service_o471064",
    api: "https://api.emailjs.com/api/v1.0/email/send"
}



