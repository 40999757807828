import React from 'react';

// CSS
import "./MiddleSection.scss";
import Paper from "@material-ui/core/Paper";
import LandingpostSection from "../Landingpost/LandingpostSection";
import Grid from "@material-ui/core/Grid";

const MiddleSection = () => {
    return (
        <div className="limitwidth-container">
            <Paper className="paper-background">
                <Grid container direction="column" spacing={6}>
                    <LandingpostSection/>
                </Grid>
            </Paper>
        </div>
    );
};

export default MiddleSection;