import {
    FETCH_COURSES_ERROR,
    FETCH_COURSES_PENDING,
    FETCH_COURSES_SUCCESS,
    UPDATE_COURSE_LEVEL_FILTER,
    UPDATE_COURSE_TECHNOLOGY_FILTER,
    UPDATE_COURSE_TEXT_FILTER,
    UPDATE_COURSE_TYPE_FILTER
} from "../constants";

const initialState = {
    pending: false,
    courses: [],
    courseTextFilter: "",
    courseTechnologyFilter: "",
    courseTypeFilter: "",
    courseLevelFilter: "",
    error: null
}

export function courses(state = initialState, action) {
    switch (action.type) {
        case FETCH_COURSES_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_COURSES_SUCCESS:
            return {
                ...state,
                pending: false,
                courses: action.courses
            }
        case FETCH_COURSES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case UPDATE_COURSE_TEXT_FILTER:
            return {
                ...state,
                courseTextFilter: action.courseTextFilter
            }
        case UPDATE_COURSE_TECHNOLOGY_FILTER:
            return {
                ...state,
                courseTechnologyFilter: action.courseTechnologyFilter
            }
        case UPDATE_COURSE_TYPE_FILTER:
            return {
                ...state,
                courseTypeFilter: action.courseTypeFilter
            }
        case UPDATE_COURSE_LEVEL_FILTER:
            return {
                ...state,
                courseLevelFilter: action.courseLevelFilter
            }
        default:
            return state;
    }
}