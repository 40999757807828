import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import CourseCards from "../../components/courseCards/CourseCards";
import TextField from "@material-ui/core/TextField";

import "./MainCoursePage.scss";
import {
    updateCourseLevelFilter,
    updateCourseTechnologyFilter,
    updateCourseTextFilter,
    updateCourseTypeFilter
} from "../../actions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TestimonialCarousel from "../landingPage/Testimonial/TestimonialCarousel";

const mapStateToProps = state => ({
    courses: state.courses.courses,
    courseTextFilter: state.courses.courseTextFilter,
    courseTechnologyFilter: state.courses.courseTechnologyFilter,
    courseTypeFilter: state.courses.courseTypeFilter,
    courseLevelFilter: state.courses.courseLevelFilter,
});

class MainCoursePage extends Component {
    getCourseLevels() {
        let courseLevels = new Set();
        this.props.courses.forEach(course => {
            course.course.categories.forEach(category => {
                if (category.slug.startsWith("level-")) {
                    courseLevels.add(category.name)
                }
            })
        })
        return courseLevels;
    }

    getCourseTechnologies() {
        let courseTechnologies = new Set();
        this.props.courses.forEach(course => {
            course.course.categories.forEach(category => {
                if (category.slug.startsWith("tech-")) {
                    courseTechnologies.add(category.name)
                }
            })
        })
        return courseTechnologies;
    }

    getCourseTypes() {
        let courseTypes = new Set();
        this.props.courses.forEach(course => {
            course.course.categories.forEach(category => {
                if (category.slug.startsWith("edut-")) {
                    courseTypes.add(category.name)
                }
            })
        })
        return courseTypes;
    }

    componentDidMount() {
        updateCourseTextFilter("");
        updateCourseTechnologyFilter("");
        updateCourseTypeFilter("");
        updateCourseLevelFilter("");
    }

    render() {
        return (
            <div id="coursePage" className="limitwidth-container">
                <Paper className="paper-testimonial">
                    <TestimonialCarousel/>
                </Paper>
                <Paper className="paper-background">
                    <Grid id="course-filter-area" container spacing={4}>
                        <h3>Kursangebote</h3>
                        <Grid className="course-search-grid" item xs={12}>
                            <TextField
                                id="course-search"
                                label="Suchen..."
                                fullWidth
                                variant="outlined"
                                onChange={e => {
                                    updateCourseTextFilter(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth className="filter-form" variant="outlined">
                                <InputLabel id="technology-label">Thema</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelId="technology-label"
                                    value={this.props.courseTechnologyFilter}
                                    onChange={e => {
                                        updateCourseTechnologyFilter(e.target.value);
                                    }}
                                    label="Thema"
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {
                                        Array.from(this.getCourseTechnologies()).map(courseTechnology => {
                                            return <MenuItem value={courseTechnology}
                                                             key={Math.floor(Math.random() * 30000000)}>{courseTechnology}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className="filter-form" variant="outlined">
                                <InputLabel id="course-type-label">Kursart</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelId="course-type-label"
                                    value={this.props.courseTypeFilter}
                                    onChange={e => {
                                        updateCourseTypeFilter(e.target.value);
                                    }}
                                    label="Kursart"
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {
                                        Array.from(this.getCourseTypes()).map(courseType => {
                                            return <MenuItem value={courseType}
                                                             key={Math.floor(Math.random() * 30000000)}>{courseType}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className="filter-form" variant="outlined">
                                <InputLabel id="course-level-label">Kurslevel</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelId="course-level-label"
                                    value={this.props.courseLevelFilter}
                                    onChange={e => {
                                        updateCourseLevelFilter(e.target.value);
                                    }}
                                    label="Kurslevel"
                                >
                                    <MenuItem value="">
                                        <em>-</em>
                                    </MenuItem>
                                    {
                                        Array.from(this.getCourseLevels()).map(courseLevel => {
                                            return <MenuItem value={courseLevel}
                                                             key={Math.floor(Math.random() * 30000000)}>{courseLevel}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid id="filter-area-border-grid" item xs={12}>
                            <div id="filter-area-border"/>
                        </Grid>
                    </Grid>
                    <Grid id="course-card-area" container justify="center" spacing={4}>
                        <CourseCards/>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default connect(mapStateToProps)(MainCoursePage);