import React, {Component} from 'react';
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";

import "./Checkout.scss";
import {Error} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import CartCards from "../../components/cartCards/CartCards";
import CheckoutForm from "./RegistrationForm";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {resetRegistrationStates} from "../../actions";
import TestimonialCarousel from "../landingPage/Testimonial/TestimonialCarousel";

const mapStateToProps = state => ({
    cart: state.order.cart,
    registrationSuccessful: state.register.success
});

class Checkout extends Component {

    componentWillUnmount() {
        resetRegistrationStates();
    }

    render() {
        if (this.props.cart.length > 0 && !this.props.registrationSuccessful) {
            return (
                <div id="checkoutPage" className="limitwidth-container">
                    <Paper className="paper-testimonial">
                        <TestimonialCarousel/>
                    </Paper>
                    <Paper className="paper-background">
                        <div id="checkout-page">
                            <h3>Anmeldung</h3>
                            <Grid container id="cart-card-area" className="cart-card-area" justify="center" spacing={4}>
                                <CartCards/>
                            </Grid>
                        </div>
                        <h3>Rechnungs-/Lieferadresse</h3>
                        <CheckoutForm/>
                    </Paper>
                    <div>
                    </div>
                </div>)
        } else if (this.props.registrationSuccessful) {
            return (
                <div id="checkoutPage" className="limitwidth-container">
                    <Paper className="paper-background">
                        <div id="checkout-page">
                            <h6> Vielen Dank für Ihre Anmeldung. Sie werden in Kürze eine Anmeldebestätigung per E-Mail
                                erhalten.</h6>
                            <Link to="/">
                                <Button color="primary"> Zurück zur Startseite </Button>
                            </Link>
                        </div>
                    </Paper>
                </div>
            )
        } else {
            return (
                <div className="limitwidth-container">
                    <Paper className="paper-background">
                        <h3>Bestellung</h3>
                        <div id="checkout-page">
                            <p><Error/> Sie haben noch keine Kurse zur Anmeldung hinzugefügt.</p>
                        </div>
                    </Paper>
                </div>
            );
        }
    }
}


export default connect(mapStateToProps)(Checkout);