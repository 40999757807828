import * as actions from "../constants";
import store from "../configureStore";

// Pages
export function fetchPagesPending() {
    return {
        type: actions.FETCH_PAGES_PENDING
    }
}

export function fetchPagesSuccess(pages) {
    return {
        type: actions.FETCH_PAGES_SUCCESS,
        pages: pages
    }
}

export function fetchPagesError(error) {
    return {
        type: actions.FETCH_PAGES_ERROR,
        error: error
    }
}

// Posts
export function fetchPostsPending() {
    return {
        type: actions.FETCH_POSTS_PENDING
    }
}

export function fetchPostsSuccess(posts) {
    return {
        type: actions.FETCH_POSTS_SUCCESS,
        posts: posts
    }
}

export function fetchPostsError(error) {
    return {
        type: actions.FETCH_POSTS_ERROR,
        error: error
    }
}

// Testimonials
export function fetchTestimonialsPending() {
    return {
        type: actions.FETCH_TESTIMONIALS_PENDING
    }
}

export function fetchTestimonialsSuccess(testimonials) {
    return {
        type: actions.FETCH_TESTIMONIALS_SUCCESS,
        testimonials: testimonials
    }
}

export function fetchTestimonialsError(error) {
    return {
        type: actions.FETCH_TESTIMONIALS_ERROR,
        error: error
    }
}

// Landingposts
export function fetchLandingpostsPending() {
    return {
        type: actions.FETCH_LANDINGPOSTS_PENDING
    }
}

export function fetchLandingpostsSuccess(landingposts) {
    return {
        type: actions.FETCH_LANDINGPOSTS_SUCCESS,
        landingposts: landingposts
    }
}

export function fetchLandingpostsError(error) {
    return {
        type: actions.FETCH_LANDINGPOSTS_ERROR,
        error: error
    }
}

export function storeNextTestimonial(testimonials) {
    const action = {
        type: actions.STORE_NEXT_TESTIMONIAL,
        current: testimonials.current,
        next: testimonials.next
    }

    store.dispatch(action);
    return action;
}

// Courses
export function fetchCoursesPending() {
    return {
        type: actions.FETCH_COURSES_PENDING
    }
}

export function fetchCoursesSuccess(courses) {
    return {
        type: actions.FETCH_COURSES_SUCCESS,
        courses: courses
    }
}

export function fetchCoursesError(error) {
    return {
        type: actions.FETCH_COURSES_ERROR,
        error: error
    }
}

export function updateCourseTextFilter(courseTextFilter) {
    const action = {
        type: actions.UPDATE_COURSE_TEXT_FILTER,
        courseTextFilter: courseTextFilter
    }

    store.dispatch(action);
    return action;
}

export function updateCourseTechnologyFilter(courseTechnologyFilter) {
    const action = {
        type: actions.UPDATE_COURSE_TECHNOLOGY_FILTER,
        courseTechnologyFilter: courseTechnologyFilter
    }

    store.dispatch(action);
    return action;
}

export function updateCourseTypeFilter(courseTypeFilter) {
    const action = {
        type: actions.UPDATE_COURSE_TYPE_FILTER,
        courseTypeFilter: courseTypeFilter
    }

    store.dispatch(action);
    return action;
}

export function updateCourseLevelFilter(courseLevelFilter) {
    const action = {
        type: actions.UPDATE_COURSE_LEVEL_FILTER,
        courseLevelFilter: courseLevelFilter
    }

    store.dispatch(action);
    return action;
}

// Products
export function fetchProductsPending() {
    return {
        type: actions.FETCH_PRODUCTS_PENDING
    }
}

export function fetchProductsSuccess(products) {
    return {
        type: actions.FETCH_PRODUCTS_SUCCESS,
        products: products
    }
}

export function fetchProductsError(error) {
    return {
        type: actions.FETCH_PRODUCTS_ERROR,
        error: error
    }
}

// Order
export function addItemToOrder(course, quantity) {
    console.log("Trying to add item to order")
    let products = store.getState().products.products;
    let productOfCourse = products.find(product => {
        let courses = product["meta_data"].find(entry => entry.key === "vibe_courses");

        if (courses && courses.value && courses.value.length > 0) {
            let foundId = courses.value.find(id => id === course.course.id)
            return !isNaN(foundId);
        }

        return false;
    });

    if (productOfCourse) {
        const action = {
            type: actions.ADD_ITEM_TO_CART,
            product: productOfCourse,
            course: course,
            quantity: quantity
        }

        store.dispatch(action);
        return action;

    } else {
        console.log("No product belongs to course!");
    }
}

export function removeItemFromOrder(productId) {
    const action = {
        type: actions.REMOVE_ITEM_FROM_CART,
        productId: productId
    }

    store.dispatch(action);
    return action;
}

export function resetCart() {
    return {
        type: actions.RESET_CART,
    }
}

export function updateItemQuantity(productId, quantity) {
    const action = {
        type: actions.UPDATE_ITEM_QUANTITY,
        productId: productId,
        quantity: quantity
    }

    store.dispatch(action);
    return action;
}

export function updateUserInfo(user) {
    const action = {
        type: actions.UPDATE_USER_INFO,
        user: user
    }

    store.dispatch(action);
    return action;
}

// registration & checkout
export function resetRegistrationStates() {
    console.log("resetRegistartionStates function called")
    const action = {
        type: actions.RESET_REGISTRATION_STATES
    }
    store.dispatch(action)
    return action;
}

export function sendOrderPending() {
    return {
        type: actions.REGISTER_ORDER_PENDING
    }
}

export function sendOrderSuccess(registration) {
    return {
        type: actions.REGISTER_ORDER_SUCCESS,
        registration: registration
    }
}

export function sendOrderError(error, errorCode) {
    return {
        type: actions.REGISTER_ORDER_ERROR,
        error: error,
        errorCode: errorCode,
    }
}

export function updateRegistrationFormFieldCustomerNote(customerNote) {
    const action = {
        type: actions.UPDATE_REGISTRATION_CUSTOMER_NOTE,
        customerNote: customerNote,
    }

    store.dispatch(action)
    return action;
}

export function updateRegistrationFormFieldIsSnackbarOpen(isSnackbarOpen) {
    const action = {
        type: actions.UPDATE_REGISTRATION_FORM_SNACKBAR,
        isSnackbarOpen: isSnackbarOpen,
    }

    store.dispatch(action)
    return action;
}

export function updateRegistrationFormFieldCouponLines(couponLines) {
    const action = {
        type: actions.UPDATE_REGISTRATION_COUPON_LINES,
        couponLines: couponLines,
    }

    store.dispatch(action)
    return action;
}

export function updateIsShippingAddressRequired(isShippingAddressRequired) {
    const action = {
        type: actions.UPDATE_IS_SHIPPING_ADDRESS_REQUIRED,
        isShippingAddressRequired: isShippingAddressRequired,
    }

    store.dispatch(action)
    return action;
}

export function updateBillingRegistrationFormFieldFirstName(billingFirstName) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_FIRST_NAME,
        billingFirstName: billingFirstName
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldLastName(billingLastName) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_LAST_NAME,
        billingLastName: billingLastName
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldStreet(billingStreet) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET,
        billingStreet: billingStreet
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldStreetNumber(billingStreetNumber) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_STREET_NUMBER,
        billingStreetNumber: billingStreetNumber
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldPostCode(billingPostcode) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE,
        billingPostcode: billingPostcode
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldCity(billingCity) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY,
        billingCity: billingCity
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldState(billingState) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE,
        billingState: billingState
    }

    store.dispatch(action);
    return action;
}

export function updateBillingRegistrationFormFieldCountry(billingCountry) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY,
        billingCountry: billingCountry
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldFirstName(shippingFirstName) {
    const action = {
        type: actions.UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_FIRST_NAME,
        shippingFirstName: shippingFirstName
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldLastName(shippingLastName) {
    const action = {
        type: actions.UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_LAST_NAME,
        shippingLastName: shippingLastName
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldStreet(shippingStreet) {
    const action = {
        type: actions.UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET,
        shippingStreet: shippingStreet
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldStreetNumber(shippingStreetNumber) {
    const action = {
        type: actions.UPDATE_SHIPPING_REGISTRATION_FORM_FIELD_STREET_NUMBER,
        shippingStreetNumber: shippingStreetNumber
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldPostCode(shippingPostcode) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_POSTCODE,
        shippingPostcode: shippingPostcode
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldCity(shippingCity) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_CITY,
        shippingCity: shippingCity
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldState(shippingState) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_STATE,
        shippingState: shippingState
    }

    store.dispatch(action);
    return action;
}

export function updateShippingRegistrationFormFieldCountry(shippingCountry) {
    const action = {
        type: actions.UPDATE_BILLING_REGISTRATION_FORM_FIELD_COUNTRY,
        shippingCountry: shippingCountry
    }

    store.dispatch(action);
    return action;
}

export function updateRegistrationFormFieldEmail(email) {
    const action = {
        type: actions.UPDATE_REGISTRATION_FORM_FIELD_EMAIL,
        email: email
    }

    store.dispatch(action);
    return action;
}

export function updateRegistrationFormFieldPhone(phone) {
    const action = {
        type: actions.UPDATE_REGISTRATION_FORM_FIELD_PHONE,
        phone: phone
    }

    store.dispatch(action);
    return action;
}

export function updateRegistrationFormFieldAGBaccepted(AGBaccepted) {
    const action = {
        type: actions.UPDATE_REGISTRATION_FORM_FIELD_AGB_ACCEPTED,
        AGBaccepted: AGBaccepted
    }

    store.dispatch(action);
    return action;

}

