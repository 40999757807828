import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as Logo} from '../../assets/images/xing-brands.svg'

// CSS
import "./IconBar.scss";

const IconBar = () => {
    return (
        <div className="icon-bar">
            <a href="https://www.linkedin.com/company/compucollege" target="_blank"
               rel="noopener noreferrer"><LinkedInIcon className="social-media-icons"/></a>
            <a href="https://www.twitter.com/compucollege" target="_blank" rel="noopener noreferrer"><TwitterIcon
                className="social-media-icons"/></a>
            <a href="https://www.facebook.com/compucollege" target="_blank" rel="noopener noreferrer"><FacebookIcon
                className="social-media-icons"/></a>
            <a href="https://www.xing.com/companies/compucollege" target="_blank" rel="noopener noreferrer"><SvgIcon
                style={{marginTop: "10px", minHeight: "38px", minWidth: "38px"}}><Logo/></SvgIcon></a>
            <div id="footer-triangle"/>
        </div>
    );
};

export default IconBar;
