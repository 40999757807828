import React from 'react';
import {Typography} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

// CSS
import "./FooterAddress.scss";

const FooterAddress = () => {
    return (
        <div className="address-footer-container">
            <div className="mobile">
                <Typography className="text"><strong>compucollege</strong></Typography>
                <Typography> Schützengraben 20</Typography>
                <Typography>CH-8200 Schaffhausen</Typography>
                <span className="iconLine"><MailIcon className="icon"/><Typography
                    className="text"><a
                    href="mailto:info@compucollege.ch">info@compucollege.ch</a></Typography></span>
                <span className="iconLine"><PhoneIcon className="icon"/><Typography
                    className="text"><a href="tel://+41526202880">+41 52 620 28 80</a></Typography></span>
            </div>
            <div className="desktop">
                <div><Typography className="text"><strong>compucollege</strong></Typography></div>
                <div><Typography>, Schützengraben 20</Typography></div>
                <div> <span className="iconLine"><MailIcon className="icon"/><Typography
                    className="text"><a
                    href="mailto:info@compucollege.ch">info@compucollege.ch</a></Typography></span></div>
                <div className="iconLine"><PhoneIcon className="icon"/><Typography
                    className="text"><a href="tel://+41526202880">+41 52 620 28 80</a></Typography></div>
            </div>
        </div>
    );
};

export default FooterAddress;
