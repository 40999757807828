import {combineReducers} from 'redux';

import {pages} from "./pages";
import {posts} from "./posts";
import {testimonials} from "./testimonials";
import {courses} from "./courses";
import {products} from "./products";
import {order} from "./order";
import {landingposts} from "./landingposts";
import {register} from "./registration"

const rootReducer = combineReducers({
    pages,
    posts,
    testimonials,
    courses,
    products,
    order,
    landingposts,
    register
});

export default rootReducer;