import React from 'react';
import FooterMiddle from "./FooterMiddle";
import FooterLeft from "./FooterAddress";

//css
import "./LowerFooterContent.scss";
import Grid from "@material-ui/core/Grid";
import LogoBar from "./LogoBar";

const LowerFooterContent = () => {
    return (
        <div className="lower-footer-container">
            <Grid container alignContent="space-around" alignItems="center" justify="space-around" spacing={4}>
                <LogoBar/>
                <Grid item xs={12}>
                    <FooterMiddle/>
                </Grid>
                <Grid item xs={12}>
                    <FooterLeft/>
                </Grid>
            </Grid>
        </div>
    );
};

export default LowerFooterContent;
