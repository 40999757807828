import {BASE_URL} from "../constants";

import {fetchLandingpostsError, fetchLandingpostsPending, fetchLandingpostsSuccess} from "./index";

function fetchLandingposts() {
    return dispatch => {
        dispatch(fetchLandingpostsPending());
        fetch(BASE_URL + "landingposts")
            .then(res => res.json())
            .then(res => {
                if (res.data !== undefined) {
                    throw(res.message);
                }
                dispatch(fetchLandingpostsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchLandingpostsError(error));
            })
    }
}

export default fetchLandingposts;