import {
    ADD_ITEM_TO_CART,
    REMOVE_ITEM_FROM_CART,
    RESET_CART,
    UPDATE_ITEM_QUANTITY,
    UPDATE_USER_INFO
} from "../constants";

const initialState = {
    cart: [],
    user: {},
    error: null,
    pending: false
}

export function order(state = initialState, action) {
    let updatedCart = [];

    switch (action.type) {
        case ADD_ITEM_TO_CART:
            let alreadyInCart = false;

            state.cart.forEach(cartItem => {
                if (cartItem.id === action.product.id) {
                    alreadyInCart = true;
                    cartItem.quantity += 1;
                }

                updatedCart.push(cartItem);
            });

            if (!alreadyInCart) {
                updatedCart.push({
                    id: action.product.id,
                    quantity: 1,
                    linkedProduct: action.product,
                    linkedCourse: action.course
                });
            }

            return {
                ...state,
                cart: updatedCart
            }

        case REMOVE_ITEM_FROM_CART:

            state.cart.forEach(cartItem => {
                if (cartItem.id !== action.productId) {
                    updatedCart.push(cartItem);
                }
            });

            return {
                ...state,
                cart: updatedCart
            }
        case RESET_CART:
            return {
                ...state,
                cart: []
            }

        case UPDATE_ITEM_QUANTITY:

            state.cart.forEach(cartItem => {
                if (cartItem.id === action.productId) {
                    if (action.quantity < 1) {
                        action.quantity = 1;
                    }

                    cartItem.quantity = action.quantity;
                }

                updatedCart.push(cartItem);
            });

            return {
                ...state,
                cart: updatedCart
            }

        case UPDATE_USER_INFO:
            return {
                ...state,
                user: action.user
            }

        default:
            return state;
    }
}