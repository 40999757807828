import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import {addItemToOrder} from "../../actions";
import {Button} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";

// CSS
import "./SingleCourseCard.scss";
import logo from '../../assets/images/compucollege_logo.jpg';
import CourseRecommendations from "./CourseRecommendations";

function mapStateToProps(state, props) {
    return {
        courses: state.courses.courses,
        courseId: props.match.params.id,
        cart: state.order.cart
    }
}

class SingleCourseCard extends Component {
    constructor(props) {
        super(props);
        this.handleAddToCartClick = this.handleAddToCartClick.bind(this);
    }

    handleAddToCartClick(event, course) {
        addItemToOrder(course, 1);
    }

    render() {
        let courses = this.props.courses;
        let course = null;

        if (courses.length > 0) {
            if ((course = courses.filter(course => course.course.id === parseInt(this.props.courseId))).length === 1) {
                course = course[0];

                return (
                    <div className="limitwidth-container" id="single-course-card-container">
                        <Paper className="paper-background">
                            <Grid className="overlay-text-container" container>
                                <Grid container className="header-layout-container">
                                    <Grid item xs={12}>
                                        <img className="course-image" src={course.course.featured_image}
                                             alt="Kurs Bild"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3>{course.course.name}</h3>
                                        <img className="logo print-only" src={logo} alt={"logo"}/>
                                        <Button id="print-button" className="page-only" onClick={() => window.print()}>Kursbeschreibung
                                            Drucken</Button>
                                    </Grid>
                                    <Grid item xs={12} dangerouslySetInnerHTML={{__html: course.description}}/>
                                    <Grid item xs={12}>
                                        <Button className="course-detail-button page-only" onClick={e => {
                                            this.handleAddToCartClick(e, course)
                                        }}><ShoppingCartIcon/> Zur Anmeldung hinzufügen</Button>
                                    </Grid>
                                    <Grid className="footer-address print-only" item xs={12}>
                                        <address>
                                            compucollege<br/>
                                            boris litmanowitsch<br/>
                                            schützengraben 20<br/>
                                            ch-8200 schaffhausen<br/>
                                            <br/>
                                            telefon 052 620 28 80<br/>
                                            fax 052 620 28 20<br/>
                                            <br/>
                                            <a href="mailto:info@compucollege.ch">info@compucollege.ch</a><br/>
                                            <a href="https://www.compucollege.ch">www.compucollege.ch</a><br/>
                                        </address>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <CourseRecommendations id={this.props.courseId}/>
                        </Paper>
                        <Snackbar className="shopping-cart-snackbar page-only" open={this.props.cart.length > 0}>
                            <Alert onClose={this.handleClose} severity="success">
                                <p>Es befinden sich Kurse bei Ihrer Anmeldung</p>
                                <Link to="/checkout">Zum Checkout</Link>
                            </Alert>
                        </Snackbar>
                    </div>
                );
            } else {
                return (
                    <CircularProgress/>
                );
            }
        } else {
            return (
                <CircularProgress/>
            );
        }
    }
}

export default connect(mapStateToProps)(SingleCourseCard);
