import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {addItemToOrder} from "../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {Error} from "@material-ui/icons";

// CSS
import "./CourseCards.scss";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";

const mapStateToProps = state => ({
    courses: state.courses.courses,
    courseTextFilter: state.courses.courseTextFilter,
    courseTechnologyFilter: state.courses.courseTechnologyFilter,
    courseTypeFilter: state.courses.courseTypeFilter,
    courseLevelFilter: state.courses.courseLevelFilter,
    cart: state.order.cart
});

class CourseCards extends Component {

    constructor(props) {
        super(props);
        this.handleAddToCartClick = this.handleAddToCartClick.bind(this);
    }

    handleAddToCartClick(event, course) {
        addItemToOrder(course, 1);
    }

    filterCourses(courses) {
        if (courses.length > 0) {
            this.props.courseTextFilter.split(" ").forEach(function (filterWord) {
                courses = courses.filter(course =>
                    JSON.stringify(course)
                        .toLowerCase()
                        .includes(filterWord))
            })
        }

        if (courses.length > 0) {
            courses = courses.filter(course => {
                let courseCategories = JSON.stringify(course.course.categories);
                return (courseCategories.includes('"' + this.props.courseTechnologyFilter + '"') || this.props.courseTechnologyFilter === "") &&
                    (courseCategories.includes('"' + this.props.courseTypeFilter + '"') || this.props.courseTypeFilter === "") &&
                    (courseCategories.includes('"' + this.props.courseLevelFilter + '"') || this.props.courseLevelFilter === "");
            })
        }

        return courses;
    }

    render() {
        let courses = this.props.courses;
        let filteredCourses = [];

        if (courses.length > 0 || this.props.courseTextFilter !== "") {
            if ((filteredCourses = this.filterCourses(courses)).length > 0) {
                return (
                    filteredCourses.map(course => {
                        return (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <Card className="image-card">
                                    <Link to={"/courses/" + course.course.id}>
                                        <CardActionArea>
                                            <CardMedia
                                                className="media-card"
                                                component="img"
                                                alt="logo"
                                                height="200"
                                                image={course.course.featured_image}
                                                title=""
                                            />
                                            <CardContent className="course-text">
                                                <Typography className="course-title" gutterBottom variant="h6"
                                                            component="h6">
                                                    {course.course.name}
                                                </Typography>
                                                <Typography className="course-short-description" variant="body2"
                                                            color="textSecondary"
                                                            component="p">{course.description.replace(/(<([^>]+)>)/gi, "").substr(0, 230) + "..."}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Link>
                                    <CardContent className="course-card-buttons">
                                        <Link to={"/courses/" + course.course.id}>
                                            <Button className="course-detail-button">Mehr erfahren</Button>
                                        </Link>
                                        <Button className="course-detail-button course-purchase-button"
                                                onClick={e => {
                                                    this.handleAddToCartClick(e, course)
                                                }}><ShoppingCartIcon/></Button>
                                    </CardContent>
                                </Card>
                                <Snackbar className="shopping-cart-snackbar" open={this.props.cart.length > 0}>
                                    <Alert onClose={this.handleClose} severity="success">
                                        <p>Es befinden sich Produkte in Ihrem Warenkorb.</p>
                                        <Link to="/checkout">Zum Checkout</Link>
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        )
                    })
                );
            } else {
                return (
                    <Grid item xs={12} key={9043589043}>
                        <p><Error/> Es wurden keine Kurse mit den angegebenen Kriterien gefunden</p>
                    </Grid>
                );
            }
        } else {
            return (
                <CircularProgress/>
            );
        }
    }
}

export default connect(mapStateToProps)(CourseCards);
